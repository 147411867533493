<script lang="ts">
  import type { CountryRoutesQuery } from '$lib/queries/generated/QueryCountryRoutes'
  import countryCodeEmoji from 'country-code-emoji'
  import { countryNameWithArticle } from 'src/utils/country-name-with-article'
  import { encodeRestrictionDestinationUrl } from 'src/utils/restrictions/restriction-urls'
  import FlagImage from '../travel-restrictions/FlagImage.svelte'

  export let routeSummary: CountryRoutesQuery['routeSummariesForRoutes'][0]

  function simpleRestrictionStatus(severity: number): string {
    if (severity == 5) {
      return 'Restricted'
    } else {
      return 'Allowed'
    }
  }

  const status = simpleRestrictionStatus(routeSummary.entrySeverity)
  const url = encodeRestrictionDestinationUrl(
    routeSummary.origin.slug,
    routeSummary.destination.slug,
    true,
  )
</script>

<li>
  <a
    href={url}
    class="relative flex items-center justify-between px-4 py-4 space-x-3 bg-white border rounded-full outline-none border-grey-dk group sm:px-6 hover:bg-grey-main focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-grey-dk"
  >
    <span class="flex items-center flex-shrink gap-2 truncate">
      <span class="text-2xl">
        {#if routeSummary?.destination?.id?.length == 2}
          {countryCodeEmoji(routeSummary?.destination?.id)}
        {:else}
          <FlagImage
            class={`h-4 sm:h-5 ${
              routeSummary?.destination?.id?.length > 0 ? 'border-grey-main' : 'border-none'
            } `}
            icon={routeSummary?.destination?.id}
            altName={routeSummary?.destination?.name}
          />
        {/if}
      </span>
      <span class="truncate">
        {routeSummary?.destination.name} from {countryNameWithArticle(routeSummary?.origin)}
      </span>
    </span>
    <img
      src={`/marketing/icons/restrictions/${status.toLowerCase()}.svg`}
      width={20}
      height={20}
      loading="lazy"
      class={`flex-shrink-0`}
      alt={routeSummary?.entryTitle}
    />
  </a>
</li>
