<script lang="ts">
  export let icon: string
  export let altName: string
  let className = ''
  export { className as class }

  if (!altName || altName.length == 0) {
    altName = 'Generic flag'
  } else {
    altName += ' flag'
  }
</script>

<img
  class={className}
  src={icon ?? '/marketing/flags/flag.svg'}
  height={24}
  width={32}
  loading="lazy"
  alt={altName}
/>
